import * as React from "react";
import { Box, Typography } from "@mui/material";
import CambridgeLogo from "../assets/images/cambridge-logo.svg";
import PalantirLogo from "../assets/images/palantir-logo.svg";
import BloombergLogo from "../assets/images/bloomberg-logo.svg";
import YCombinatorLogo from "../assets/images/yc-logo.svg";

export const TrustedInstitutions: React.FC = () => {
    return (
        <Box
            sx={theme => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                mb: 3,
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                    gap: 3,
                },
            })}
        >
            <Typography
                variant="body2"
                sx={{
                    color: "neutrals.70",
                }}
            >
                Our team brings experience from:
            </Typography>
            <Box
                sx={theme => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    flexWrap: "wrap",
                    [theme.breakpoints.down("md")]: {
                        display: "grid",
                        gridTemplateColumns: "repeat(2, auto)",
                        gap: 3,
                    },
                })}
            >
                <img src={PalantirLogo} alt="Palantir" height="25" />
                <img src={BloombergLogo} alt="Bloomberg" height="25" />
                <img src={CambridgeLogo} alt="University of Cambridge" height="25" />
                <img src={YCombinatorLogo} alt="Y Combinator" height="45" />
            </Box>
        </Box>
    );
};
