import { useTheme } from "@mui/material";

import { Box } from "@mui/material";
import * as React from "react";

interface MediaContainerProps {
    width: number;
    height: number;
    children: React.ReactNode;
    startingBottom?: number;
}

export const MediaContainer: React.FC<MediaContainerProps> = ({ width, height, children, startingBottom = 0 }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                pb: 0,
                position: "relative",
                width: width,
                height: height,
                mx: "auto",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "70%",
                    height: "100%",
                    bgcolor: "secondary.main",
                    zIndex: 1,
                    borderRadius: 3,
                    [theme.breakpoints.only("xxxs")]: { bottom: startingBottom + 5 },
                    [theme.breakpoints.only("xxs")]: { bottom: startingBottom + 8 },
                    [theme.breakpoints.only("xs")]: { bottom: startingBottom + 10 },
                    [theme.breakpoints.up("sm")]: { bottom: startingBottom + 15 },
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "80%",
                    height: "100%",
                    bgcolor: "#FBBC45",
                    zIndex: 2,
                    borderRadius: 3,
                    border: 2,
                    borderColor: "white",
                    [theme.breakpoints.only("xxxs")]: { bottom: startingBottom + 2 },
                    [theme.breakpoints.only("xxs")]: { bottom: startingBottom + 4 },
                    [theme.breakpoints.only("xs")]: { bottom: startingBottom + 6 },
                    [theme.breakpoints.up("sm")]: { bottom: startingBottom + 0 },
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 2,
                    borderColor: "primary.main",
                    backgroundColor: "white",
                    borderRadius: 3,
                    zIndex: 3,
                    overflow: "hidden",
                    [theme.breakpoints.only("xxxs")]: { bottom: startingBottom - 5 },
                    [theme.breakpoints.only("xxs")]: { bottom: startingBottom - 8 },
                    [theme.breakpoints.only("xs")]: { bottom: startingBottom - 10 },
                    [theme.breakpoints.up("sm")]: { bottom: startingBottom - 15 },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
