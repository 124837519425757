import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as BgPattern } from "../assets/images/bg-pattern.svg";
import { SECTIONS } from "./features";

const StyledBgPattern = styled(BgPattern)(({ theme }) => ({
    position: "absolute",
    left: 0,
    top: "0",
    width: "90%",
    height: "50%",
    opacity: 0.35,
    zIndex: 0,
    mixBlendMode: "multiply",
    bgcolor: "surface.25",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
    [theme.breakpoints.up("md")]: {
        display: "block",
    },
}));

export const MobileFeaturesSection: React.FC = () => {
    const scrollerRef = useRef<HTMLDivElement>(null);

    return (
        <Box
            id="features-section"
            ref={scrollerRef}
            sx={theme => ({
                display: "flex",
                [theme.breakpoints.down("sm")]: {
                    px: 4,
                },
                [theme.breakpoints.up("md")]: {
                    px: 6,
                },
                bgcolor: "surface.25",
                border: 1,
                borderColor: "neutrals.30",
                borderRadius: 2,
                minHeight: "85vh",
                mx: 2,
                [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 4,
                },
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    gap: 4,
                },
            })}
        >
            {/* Static Top/Left Side */}
            <Box
                sx={theme => ({
                    position: "relative",

                    [theme.breakpoints.down("sm")]: {
                        pt: 4,
                    },
                })}
            >
                <StyledBgPattern />
                <Typography
                    variant="h2"
                    sx={theme => ({
                        position: "relative",
                        zIndex: 1,
                        fontFamily: "'Garamond-Light-Cond'",
                        color: "secondary.main",
                        fontWeight: "500",
                        lineHeight: 1.2,
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "2.5rem",
                            textAlign: "center",
                            px: 2,
                        },
                    })}
                >
                    Designed from
                    <br />
                    the ground-up for
                    <br />
                    <i>Professional Services</i>
                </Typography>
            </Box>

            {/* Scrollable Bottom/Right Side */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    py: 1,
                    height: "auto",
                }}
            >
                {SECTIONS.map(section => (
                    <Box
                        key={section.title}
                        sx={theme => ({
                            bgcolor: "surface.75",
                            borderRadius: 1,
                            height: "auto",
                            minHeight: "64px",
                            overflow: "hidden",
                            cursor: "default",
                            "&:hover": {
                                bgcolor: undefined,
                            },
                            [theme.breakpoints.down("sm")]: {
                                mx: 1,
                            },
                        })}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                px: 3,
                                py: 1.5,
                                pt: 3,
                                transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                                minHeight: "64px",
                                rowGap: 1,
                            }}
                        >
                            <Typography variant="body1" fontWeight="medium">
                                {section.title}
                            </Typography>
                            <Box
                                sx={{
                                    opacity: 1,
                                    transform: "translateY(0)",
                                    transition: "all 0.8s cubic-bezier(0.4, 0, 0.2, 1)",
                                    visibility: "visible",
                                    height: "auto",
                                }}
                            >
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                    {section.description}
                                </Typography>

                                {section.media}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
