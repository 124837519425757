import React from "react";
import { Box } from "@mui/material";
import { Header } from "./components/Header";
import { getScrollbarSx } from "./scrollbarProps";

export const AppContentWithHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                height: "100vh",
                overflowY: "hidden",
                maxWidth: "100vw",
                backgroundColor: "#F4F2EF",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "stretch",
                minWidth: 320,
                overflowX: "hidden",
                position: "relative",
            }}
        >
            <Box
                id="scroll-container"
                sx={{
                    ...getScrollbarSx("#F4F2EF"),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    flexGrow: 1,
                    overflowX: "hidden",
                    overflowY: "auto",
                }}
            >
                <Header />
                {children}
            </Box>
        </Box>
    );
};
