import React from "react";
import { Box, Typography } from "@mui/material";
import { useIsMobile } from "../useCurrentBreakpoint";
import { TalkToUsButton } from "../talkToUsButton";

export const TopSection: React.FC = () => {
    const isMobile = useIsMobile();
    return (
        <Box
            sx={theme => ({
                mx: 1,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                // rowGap: breakpoint === "xxxs" ? 0 : 3,
                overflowY: "hidden",
                overflowX: "hidden",
                [theme.breakpoints.down("xs")]: {
                    rowGap: 2,
                },
                [theme.breakpoints.up("sm")]: {
                    rowGap: 4,
                },
            })}
        >
            <Box
                sx={theme => ({
                    display: "flex",
                    flexDirection: "column",
                    px: 6,
                    [theme.breakpoints.down("sm")]: {
                        rowGap: 3,
                        px: 3,
                    },
                    // rest
                    [theme.breakpoints.up("md")]: {
                        rowGap: 2,
                    },
                })}
            >
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                    <Typography
                        variant="caption"
                        sx={{
                            color: "secondary.main",
                            maxWidth: "600px",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            textTransform: "uppercase",
                            fontWeight: "medium",
                        }}
                    >
                        AI-Powered Workspace for Professional Services
                    </Typography>
                    <Typography
                        variant={isMobile ? "h3" : "h2"}
                        sx={{
                            fontFamily: "'Garamond-Light-Cond'",
                            color: "secondary.main",
                            fontWeight: "500",
                            lineHeight: 1.0,
                        }}
                    >
                        {!isMobile ? (
                            <>
                                Put AI to work for your firm
                                <br />
                                <i>with your knowledge</i>
                            </>
                        ) : (
                            <>
                                Put AI to work
                                <br />
                                for your firm
                                <br />
                                with your
                                <br />
                                <i>knowledge</i>
                            </>
                        )}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignSelf: "center", alignItems: "center", gap: 2 }}>
                    {/* <Button
                        id="try-it-button"
                        data-ph-capture-attribute-button="try-it-button"
                        variant="contained"
                        color="secondary"
                        href="https://app.answergrid.ai/try-it"
                        target="_blank"
                        size="large"
                        sx={{
                            color: "white",
                            borderRadius: 5,
                            textTransform: "unset",
                            boxShadow: 0,
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
                            Book a demo
                        </Typography>
                    </Button> */}
                    <TalkToUsButton />
                </Box>
            </Box>
        </Box>
    );
};
