import { useTheme } from "@mui/material";
import { useCurrentBreakpoint } from "./useCurrentBreakpoint";

const ASPECT_RATIO = 640 / 340;

export function useVideoDimensions(
    containerDimensions: { width: number; height: number },
    aspectRatio: number = ASPECT_RATIO,
): [number, number] {
    const theme = useTheme();
    const currentBreakpoint = useCurrentBreakpoint();
    const breakpointWidth = theme.breakpoints.values[currentBreakpoint];
    const maxWidth = containerDimensions.width * 0.9;
    const maxHeight = containerDimensions.height * 0.7;

    let videoWidth = breakpointWidth !== 0 ? Math.min(maxWidth, breakpointWidth * 0.9) : maxWidth;
    let videoHeight = videoWidth / aspectRatio;

    const remainingHeight = containerDimensions.height - videoHeight;

    if (remainingHeight < 300) {
        videoHeight = videoHeight - (300 - remainingHeight);
        videoWidth = videoHeight * aspectRatio;
    }

    if (videoHeight > maxHeight) {
        videoHeight = maxHeight;
        videoWidth = videoHeight * aspectRatio;
    }

    // Ensure width is not more than 90% of container width
    if (videoWidth > maxWidth) {
        videoWidth = maxWidth;
        videoHeight = videoWidth / aspectRatio;
    }

    return [videoWidth, videoHeight];
}
