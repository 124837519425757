import React from "react";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";

export const Footer: React.FC = () => {
    return (
        <Box sx={theme => ({ bgcolor: "surface.50", mt: 6, py: 4, [theme.breakpoints.down("sm")]: { mt: 0 } })}>
            <Box
                sx={{
                    mx: "auto",
                    px: 10,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {/* Top Section with Address and Social Links */}
                <Box
                    sx={{
                        display: "flex",
                        mb: 4,
                    }}
                >
                    {/* Address Section */}
                    <Box sx={{ maxWidth: "300px" }}>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            San Francisco
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            California
                        </Typography>
                        <Typography variant="body2">United States</Typography>
                    </Box>

                    {/* Social Links */}
                    <Box sx={{ display: "flex", ml: "40%", flexDirection: "column", gap: 1 }}>
                        <Link
                            href="https://twitter.com/answergrid"
                            target="_blank"
                            typography="body2"
                            sx={{ color: "text.primary", textDecoration: "none" }}
                        >
                            Twitter
                        </Link>
                        <Link
                            href="https://linkedin.com/company/answergrid"
                            target="_blank"
                            typography="body2"
                            sx={{ color: "text.primary", textDecoration: "none" }}
                        >
                            LinkedIn
                        </Link>
                        <Link
                            href="mailto:founders@answergrid.ai"
                            typography="body2"
                            sx={{ color: "text.primary", textDecoration: "none" }}
                        >
                            Email
                        </Link>
                    </Box>
                </Box>

                {/* Bottom Section with Documents and Copyright */}
                <Box
                    sx={theme => ({
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 4,
                        [theme.breakpoints.down("sm")]: {
                            flexDirection: "column",
                            alignItems: "center",
                        },
                    })}
                >
                    {/* <Box sx={{ display: "flex", alignItems: "center", gap: 4, flexDirection: "row" }}>
                        <Link
                            href="/terms"
                            sx={{
                                color: "text.primary",
                                textDecoration: "none",
                                typography: "body2",
                                pointerEvents: "none",
                                opacity: 0.5,
                            }}
                        >
                            Terms of Service
                        </Link>
                        <Link
                            href="/privacy"
                            sx={{
                                color: "text.primary",
                                textDecoration: "none",
                                typography: "body2",
                                pointerEvents: "none",
                                opacity: 0.5,
                            }}
                        >
                            Data Statement
                        </Link>
                        <Link
                            href="/cookies"
                            sx={{
                                color: "text.primary",
                                textDecoration: "none",
                                typography: "body2",
                                pointerEvents: "none",
                                opacity: 0.5,
                            }}
                        >
                            Cookie Preferences
                        </Link>
                    </Box> */}
                    {/* Copyright */}
                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.secondary",
                            textAlign: "left",
                            mt: 4,
                        }}
                    >
                        © 2024 AnswerGrid, Inc. All rights reserved.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
