import * as React from "react";
import { Box, useTheme } from "@mui/material";
import { useIsMobile } from "./useCurrentBreakpoint";

interface LanderMediaTopPartProps {
    topPart: React.ReactNode;
    media: React.ReactNode;
}

export const LanderMediaTopPart = React.forwardRef<HTMLDivElement, LanderMediaTopPartProps>(
    ({ topPart, media }, ref) => {
        const theme = useTheme();
        const isMobile = useIsMobile();

        return (
            <Box
                ref={ref}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "center",
                    mx: 2,
                    borderRadius: 2,
                    bgcolor: "surface.25",
                    border: 1,
                    borderColor: "neutrals.30",
                    minHeight: isMobile ? "620px" : "500px",
                    height: isMobile ? "620px" : "82vh",
                    overflowY: "hidden",
                    flexGrow: 1,
                    overflowX: "hidden",
                    mb: 3,
                    position: "relative",
                }}
            >
                <Box
                    maxWidth="lg"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        justifyContent: "space-between",
                        flexGrow: 1,
                        // pt: currentBreakpoint === "xs" ? 8 : 6,
                        [theme.breakpoints.only("xxxs")]: {
                            pt: 6,
                        },
                        [theme.breakpoints.only("xxs")]: {
                            pt: 10,
                        },
                        [theme.breakpoints.only("xs")]: {
                            pt: 14,
                        },
                        [theme.breakpoints.only("sm")]: {
                            pt: 10,
                        },
                        // rest
                        [theme.breakpoints.up("md")]: {
                            pt: 6,
                        },
                    }}
                >
                    {topPart}
                    {media}
                </Box>
            </Box>
        );
    },
);

LanderMediaTopPart.displayName = "LanderMediaTopPart";
