import React, { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";
import ReactGA from "react-ga4";
import { AppContentWithHeader } from "./AppContentWithHeader";
import { MediaContainer } from "./MediaContainer";
import { LanderMediaTopPart } from "./LanderMediaTopPart";
import { useVideoDimensions } from "./useVideoDimensions";
import { SecuritySection } from "./sections/SecuritySection";
import { TopSection } from "./sections/TopKnowledgeLanderSection";
import { Footer } from "./sections/FooterSection";
import { TrustedInstitutions } from "./sections/TrustedInstitutionsSection";
import { AnswersAtScaleSection } from "./sections/AnswersAtScaleSection";
import { VideoPlayer } from "./videoPlayer";
import { ScrollingFeaturesSection } from "./sections/ScrollingFeaturesSection";
import { useIsMobile } from "./useCurrentBreakpoint";
import { MobileFeaturesSection } from "./sections/MobileFeaturesSection";

export const KnowledgeLander: React.FC = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Workspace Landing page",
    });

    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const wasEverDesktop = useRef(false);

    useEffect(() => {
        if (!isMobile) {
            wasEverDesktop.current = true;
        }
    }, [isMobile]);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerDimensions({
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [videoWidth, videoHeight] = useVideoDimensions(containerDimensions, 16 / 10);

    const video = (
        <MediaContainer width={videoWidth} height={videoHeight}>
            <VideoPlayer src="https://AnswerGrid.b-cdn.net/Main%20Legend%20Demo.m4v" />
        </MediaContainer>
    );

    return (
        <AppContentWithHeader>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center" }}>
                <LanderMediaTopPart ref={containerRef} topPart={<TopSection />} media={video} />
                <TrustedInstitutions />
                {wasEverDesktop.current ? <ScrollingFeaturesSection /> : <MobileFeaturesSection />}
                <SecuritySection />
                <AnswersAtScaleSection />
                <Footer />
            </Box>
        </AppContentWithHeader>
    );
};
