import React from "react";
import { Button, Typography } from "@mui/material";

export const TalkToUsButton: React.FC = () => (
    <Button
        id="book-demo-button"
        data-ph-capture-attribute-button="book-demo-button"
        variant="outlined"
        size="large"
        href="https://calendly.com/noah-answergrid/30-min-meeting"
        target="_blank"
        sx={{
            color: "secondary.main",
            borderColor: "divider",
            textTransform: "unset",
            borderRadius: 8,
            fontWeight: "medium",
        }}
    >
        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
            Book a demo
        </Typography>
    </Button>
);
