import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as BgPattern } from "../assets/images/bg-pattern.svg";
import { SECTIONS } from "./features";

const StyledBgPattern = styled(BgPattern)({
    position: "absolute",
    left: 0,
    bottom: 0,
    maxWidth: "100%",
    height: "45vh",
    zIndex: 0,
    display: "block",
    preserveAspectRatio: "none",
    opacity: 0.2,
    mask: "radial-gradient(circle at center, black 0%, transparent 100%)",
    WebkitMask: "radial-gradient(circle at center, black 0%, transparent 100%)",
    "& svg": {
        // width: "100%",
        // height: "100%",
        // viewBox: "0 0 1408 800",
        shapeRendering: "crispEdges",
        paintOrder: "stroke",
    },
    "& *": {
        // vectorEffect: "non-scaling-stroke",
        // strokeWidth: "0.25",
        // fill: "none",
        // stroke: "currentColor",
        // opacity: 0.25,
    },
    "& path, & rect": {
        transform: "scale(3, 4)",
        transformOrigin: "center center",
        // mixBlendMode: "normal",
        // isolation: "isolate",
    },
});

export class ScrollingFeaturesSection extends React.Component<
    Record<string, never>,
    {
        activeSection: number;
        isCarouselActive: boolean;
        inView: boolean;
        isScrollLocked: boolean;
        scrollSensitivity: number;
    }
> {
    private lastScrollPosition: number;
    private accumulatedDelta: number;
    private featuresRef: React.RefObject<HTMLDivElement>;
    private scrollContainer: HTMLElement | null;
    private observer: IntersectionObserver | null;
    private touchStartY = 0;
    private scrollLockTimeout: NodeJS.Timeout | null = null;

    constructor(props: Record<string, never>) {
        super(props);
        this.state = {
            activeSection: 0,
            isCarouselActive: false,
            inView: false,
            isScrollLocked: false,
            scrollSensitivity: 1,
        };

        this.lastScrollPosition = 0;
        this.accumulatedDelta = 0;
        this.featuresRef = React.createRef();
        this.scrollContainer = null;
        this.observer = null;
    }

    componentDidMount() {
        window.addEventListener("wheel", this.handleWheel, { passive: false });
        window.addEventListener("touchstart", this.handleTouchStart, { passive: false });
        window.addEventListener("touchmove", this.handleTouchMove, { passive: false });

        if (this.featuresRef.current) {
            const options = {
                threshold: 1.0,
                root: null,
            };
            this.observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.target === this.featuresRef.current) {
                        this.setState({ inView: entry.isIntersecting });
                    }
                });
            }, options);
            this.observer.observe(this.featuresRef.current);
        }

        this.scrollContainer = document.getElementById("scroll-container");
    }

    componentWillUnmount() {
        if (this.observer && this.featuresRef.current) {
            this.observer.unobserve(this.featuresRef.current);
        }

        window.removeEventListener("wheel", this.handleWheel);
        window.removeEventListener("touchstart", this.handleTouchStart);
        window.removeEventListener("touchmove", this.handleTouchMove);

        if (this.scrollContainer) {
            this.scrollContainer.style.overflowY = "auto";
        }

        if (this.scrollLockTimeout) {
            clearTimeout(this.scrollLockTimeout);
        }
    }

    componentDidUpdate(
        prevProps: Record<string, never>,
        prevState: { isCarouselActive: boolean; activeSection: number; inView: boolean },
    ) {
        if (
            this.state.isCarouselActive !== prevState.isCarouselActive ||
            this.state.activeSection !== prevState.activeSection
        ) {
            if (this.scrollContainer && this.featuresRef.current) {
                if (this.state.isCarouselActive) {
                    // Immediately apply scroll position
                    this.scrollContainer.style.overflowY = "hidden";
                    this.scrollContainer.style.scrollbarGutter = "stable";
                    const containerRect = this.scrollContainer.getBoundingClientRect();
                    const featuresRect = this.featuresRef.current.getBoundingClientRect();
                    const headerHeight = 64;
                    const relativePosition =
                        featuresRect.top - containerRect.top + this.scrollContainer.scrollTop - headerHeight;
                    this.scrollContainer.scrollTop = relativePosition;
                } else {
                    this.scrollContainer.style.overflowY = "auto";
                    this.scrollContainer.style.scrollbarGutter = "auto";
                }
            }
        }

        if (this.state.inView !== prevState.inView) {
            if (this.scrollContainer) {
                if (this.state.inView) {
                    this.setState({ isCarouselActive: true });
                    this.lastScrollPosition = this.scrollContainer.scrollTop;

                    // this.featuresRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
                } else {
                    this.setState({ isCarouselActive: false });
                }
            }
        }

        if (this.state.inView !== prevState.inView && this.state.inView) {
            this.setState({ isScrollLocked: true });
            this.accumulatedDelta = 0;
            this.scrollLockTimeout = setTimeout(() => {
                this.setState({ isScrollLocked: false });
            }, 300);
        }
    }

    handleWheel = (e: WheelEvent) => {
        if (!this.state.isCarouselActive || this.state.isScrollLocked) return;

        e.preventDefault();
        this.accumulatedDelta += e.deltaY;
        this.handleScrollLogic();
    };

    handleTouchStart = (e: TouchEvent) => {
        if (!this.state.isCarouselActive) return;

        this.touchStartY = e.touches[0].clientY;
        e.preventDefault();
    };

    handleTouchMove = (e: TouchEvent) => {
        if (!this.state.isCarouselActive || this.state.isScrollLocked) return;

        e.preventDefault();
        const touchDelta = this.touchStartY - e.touches[0].clientY;
        this.accumulatedDelta += touchDelta;
        this.touchStartY = e.touches[0].clientY;
        this.handleScrollLogic();
    };

    handleScrollLogic = () => {
        const BASE_THRESHOLD = 100;
        const threshold = BASE_THRESHOLD * this.state.scrollSensitivity;

        if (Math.abs(this.accumulatedDelta) >= threshold) {
            this.setState({ scrollSensitivity: 5 });

            if (this.accumulatedDelta > 0 && this.state.activeSection < SECTIONS.length - 1) {
                this.setState(prevState => ({ activeSection: prevState.activeSection + 1 }));
            } else if (this.accumulatedDelta < 0 && this.state.activeSection > 0) {
                this.setState(prevState => ({ activeSection: prevState.activeSection - 1 }));
            } else if (this.accumulatedDelta > 0 && this.state.activeSection === SECTIONS.length - 1) {
                this.setState({ isCarouselActive: false });
            } else if (this.accumulatedDelta < 0 && this.state.activeSection === 0) {
                this.setState({ isCarouselActive: false });
            }
            this.accumulatedDelta = 0;

            setTimeout(() => {
                this.setState({ scrollSensitivity: 1 });
            }, 750);
        }
    };

    render() {
        const { activeSection } = this.state;

        return (
            <Box
                id="features-section"
                ref={this.featuresRef}
                sx={{
                    scrollSnapAlign: "start",
                    scrollSnapStop: "always",
                    display: "flex",
                    scrollMarginTop: "64px",
                    bgcolor: "surface.25",
                    border: 1,
                    borderColor: "neutrals.30",
                    borderRadius: 2,
                    minHeight: "85vh",
                    mx: 2,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 4,
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        flex: 1,
                        py: 5,
                    }}
                >
                    <StyledBgPattern />
                    <Typography
                        variant="h2"
                        sx={{
                            position: "relative",
                            zIndex: 1,
                            maxWidth: "500px",
                            fontFamily: "'Garamond-Light-Cond'",
                            color: "secondary.main",
                            fontWeight: "500",
                            lineHeight: 1.2,
                            mb: 3,
                            px: 6,
                        }}
                    >
                        Designed from
                        <br />
                        the ground-up for
                        <br />
                        <i>Professional Services</i>
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: "45%",
                        pr: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        py: 1,
                        height: "85vh",
                        zIndex: 1,
                    }}
                >
                    {SECTIONS.map((section, index) => (
                        <Box
                            key={section.title}
                            sx={{
                                bgcolor: index === activeSection ? "surface.75" : "surface.100",
                                borderRadius: 1,
                                transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                                height: index === activeSection ? "auto" : "64px",
                                minHeight: "64px",
                                overflow: "hidden",
                                cursor: "pointer",
                                flex: index === activeSection ? 1 : "0 0 64px",
                                maxHeight: "85vh",
                                "&:hover": {
                                    bgcolor: "surface.200",
                                },
                            }}
                            onClick={() => this.setState({ activeSection: index })}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flex: 0,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    px: 6,
                                    py: 1.5,
                                    overflow: "hidden",
                                    pt: index === activeSection ? 3 : undefined,
                                    transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                                    minHeight: "64px",
                                    rowGap: index === activeSection ? 1 : undefined,
                                }}
                            >
                                <Typography variant="body1" fontWeight="medium" sx={{ flexGrow: 0 }}>
                                    {section.title}
                                </Typography>
                                {index === activeSection && (
                                    <Box>
                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                            {section.description}
                                        </Typography>
                                        {section.media}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
}
