import React, { useState } from "react";
import { Box, Button, Menu, MenuItem, AppBar, Toolbar, Stack, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { scroller } from "react-scroll";
import { useCurrentBreakpoint } from "../useCurrentBreakpoint";
import { HambergerMenu, SearchNormal1, Grid2, ArrowRight } from "iconsax-react";
import { Drawer, IconButton } from "@mui/material";
import { ReactComponent as Logo } from "../assets/images/logo-lockup-primary.svg";

const hashToSectionId = {
    features: "features-section",
    security: "security-section",
    // add other mappings as needed
};

export const Header = () => {
    const navigate = useNavigate();
    const [productsAnchorEl, setProductsAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleProductsHover = (event: React.MouseEvent<HTMLElement>) => {
        setProductsAnchorEl(event.currentTarget);
    };

    const handleProductsClose = React.useCallback(() => {
        setProductsAnchorEl(null);
    }, []);

    const location = useLocation();

    const isSheets = location.pathname === "/sheets";
    const isWorkspace = location.pathname === "/";

    const handleWorkspaceClick = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    const handleSheetsClick = React.useCallback(() => {
        navigate("/sheets");
    }, [navigate]);

    const scrollTo = React.useCallback((elementId: string) => {
        scroller.scrollTo(elementId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -64,
            containerId: "scroll-container",
        });
    }, []);

    const currentBreakpoint = useCurrentBreakpoint();
    const isMobile = ["xxxs", "xxs", "xs", "sm"].includes(currentBreakpoint);

    const theme = useTheme();

    React.useEffect(() => {
        // Check sessionStorage instead of hash
        const savedSection = sessionStorage.getItem("scrollTarget");
        if (savedSection && location.pathname === "/") {
            const sectionId = hashToSectionId[savedSection as keyof typeof hashToSectionId];

            if (sectionId != null) {
                // Clear the saved section
                sessionStorage.removeItem("scrollTarget");
                // Scroll to the element
                setTimeout(() => {
                    scroller.scrollTo(sectionId, {
                        duration: 800,
                        delay: 0,
                        smooth: "easeInOutQuart",
                        offset: -64,
                        containerId: "scroll-container",
                    });
                }, 100);
            }
        }
    }, [location]);

    // Update the navigation handlers
    const handleFeatureClick = () => {
        if (location.pathname === "/sheets") {
            sessionStorage.setItem("scrollTarget", "features");
            navigate(`/`);
        } else {
            scrollTo("features-section");
        }
    };

    const handleSecurityClick = () => {
        if (location.pathname === "/sheets") {
            sessionStorage.setItem("scrollTarget", "security");
            navigate(`/`);
        } else {
            scrollTo("security-section");
        }
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                boxShadow: "none",
                backgroundColor: "#F4F2EF",
                position: "sticky",
                top: 0,
                zIndex: 1000,
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                {/* Logo */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: 2,
                        pb: 1.5,
                        height: 48,
                        overflowX: "hidden",
                        overflowY: "hidden",
                    }}
                >
                    <Box
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer", overflowY: "hidden" }}
                        onClick={() => navigate("/")}
                    >
                        <Logo />
                        {/* <CircleOutlinedIcon
                            sx={theme => ({
                                stroke: theme.palette.secondary.main,
                                strokeWidth: "2px",
                                fontSize: "32px",
                                mb: "8px",
                                mr: 1,
                                [theme.breakpoints.only("xxxs")]: {
                                    fontSize: "22px",
                                    mb: "4px",
                                    strokeWidth: "2px",
                                },
                                [theme.breakpoints.only("xxs")]: {
                                    fontSize: "22px",
                                    mb: "4px",
                                    strokeWidth: "2px",
                                },
                                [theme.breakpoints.up("xs")]: {
                                    fontSize: "32px",
                                },
                            })}
                        />
                        <Typography
                            variant={isMobile ? "h5" : "h4"}
                            sx={{
                                fontFamily: "'Garamond-Light-Cond'",
                                color: "secondary.main",
                                mr: 0.5,
                                fontSize: "30px",
                            }}
                        >
                            AnswerGrid
                        </Typography> */}
                    </Box>
                </Box>
                {!isMobile ? (
                    <Stack
                        direction="row"
                        spacing={4}
                        sx={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <RouteButton text="Features" onClick={handleFeatureClick} />
                        <RouteButton text="Security" onClick={handleSecurityClick} />
                        {/* <RouteButton text="Company" onClick={() => navigate("/company")} /> */}
                        {/* <RouteButton
                            text="Blog"
                            onClick={() => (window.location.href = "https://blog.answergrid.ai")}
                        /> */}
                        <RouteButton
                            text="Products"
                            onClick={handleProductsHover}
                            endIcon={<KeyboardArrowDownIcon />}
                        />
                    </Stack>
                ) : (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setMobileMenuOpen(true)}
                        sx={{ color: "secondary.main" }}
                    >
                        <HambergerMenu size={24} />
                    </IconButton>
                )}
                {!isMobile && (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="outlined"
                            onClick={() => (window.location.href = "https://calendly.com/noah-answergrid/30min")}
                            sx={{
                                textTransform: "none",
                                borderColor: "neutrals.30",
                                color: "neutrals.80",
                                "&:hover": { borderColor: "neutrals.30" },
                            }}
                        >
                            Book a demo
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ display: "none" }} // Hidden for now
                        >
                            Try demo
                        </Button>
                    </Stack>
                )}

                {/* Products Dropdown */}
                <Menu
                    anchorEl={productsAnchorEl}
                    open={Boolean(productsAnchorEl)}
                    // open={true}
                    onClose={handleProductsClose}
                    MenuListProps={{
                        onMouseLeave: handleProductsClose,
                        sx: { py: 0 },
                    }}
                    slotProps={{
                        paper: {
                            elevation: 3,
                            sx: { p: 1, width: 164 },
                        },
                    }}
                >
                    <MenuItem
                        onClick={handleWorkspaceClick}
                        sx={{
                            backgroundColor: isWorkspace ? "surface.50" : "transparent",
                            borderRadius: 2,
                            px: 1,
                            mb: 0.5,
                            "&:hover": { backgroundColor: isWorkspace ? "surface.50" : undefined },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <SearchNormal1
                                    size={20}
                                    variant={isWorkspace ? "Bold" : "Outline"}
                                    color={isWorkspace ? theme.palette.secondary.main : undefined}
                                />
                                <Typography
                                    variant="body2"
                                    fontWeight={isWorkspace ? 500 : 400}
                                    color={isWorkspace ? "secondary.main" : undefined}
                                >
                                    Workspace
                                </Typography>
                            </Box>
                            {!isWorkspace && (
                                <ArrowRight
                                    size={18}
                                    color={theme.palette.neutrals[70]}
                                    style={{ transform: "rotate(-45deg)" }}
                                />
                            )}
                        </Box>
                    </MenuItem>
                    <MenuItem
                        onClick={handleSheetsClick}
                        sx={{
                            backgroundColor: isSheets ? "surface.50" : "transparent",
                            borderRadius: 2,
                            px: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            "&:hover": { backgroundColor: isSheets ? "surface.50" : undefined },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}
                            >
                                <Grid2
                                    size={20}
                                    variant={isSheets ? "Bold" : "Outline"}
                                    color={isSheets ? theme.palette.secondary.main : undefined}
                                />
                                <Typography
                                    variant="body2"
                                    fontWeight={isSheets ? 500 : 400}
                                    color={isSheets ? "secondary.main" : undefined}
                                >
                                    Sheets
                                </Typography>
                            </Box>
                            {!isSheets && (
                                <ArrowRight
                                    size={18}
                                    color={theme.palette.neutrals[70]}
                                    style={{ transform: "rotate(-45deg)" }}
                                />
                            )}
                        </Box>
                    </MenuItem>
                </Menu>

                {/* Add Mobile Drawer */}
                <Drawer
                    anchor="right"
                    open={mobileMenuOpen}
                    onClose={() => setMobileMenuOpen(false)}
                    PaperProps={{
                        sx: {
                            width: "70%",
                            maxWidth: "300px",
                            backgroundColor: "#F4F2EF",
                            p: 2,
                            display: "flex",
                        },
                    }}
                >
                    <Stack spacing={2} sx={{ flex: 1 }}>
                        <CircleOutlinedIcon
                            sx={theme => ({
                                stroke: theme.palette.secondary.main,
                                strokeWidth: "2px",
                                fontSize: "32px",
                                mb: "8px",
                                mr: 1,
                                alignSelf: "center",
                            })}
                        />

                        <Button
                            color="secondary"
                            onClick={() => {
                                // TODO: Fix
                                if (location.pathname === "/sheets") {
                                    handleWorkspaceClick();
                                }
                                scrollTo("features-section");
                                setMobileMenuOpen(false);
                            }}
                            sx={{ justifyContent: "center", textTransform: "none" }}
                        >
                            Features
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                if (location.pathname === "/sheets") {
                                    handleWorkspaceClick();
                                }
                                scrollTo("security-section");
                                setMobileMenuOpen(false);
                            }}
                            sx={{ justifyContent: "center", textTransform: "none" }}
                        >
                            Security
                        </Button>
                        {/* <Button
                            color="secondary"
                            onClick={() => {
                                navigate("/company");
                                setMobileMenuOpen(false);
                            }}
                            sx={{ justifyContent: "center", textTransform: "none" }}
                        >
                            Company
                        </Button> */}
                        {/* <Button
                            color="secondary"
                            onClick={() => {
                                window.location.href = "https://blog.answergrid.ai";
                                setMobileMenuOpen(false);
                            }}
                            sx={{ justifyContent: "center", textTransform: "none" }}
                        >
                            Blog
                        </Button> */}
                    </Stack>
                    {/* Add Products Section */}
                    {!isWorkspace && (
                        <Button
                            color="secondary"
                            onClick={() => {
                                handleWorkspaceClick();
                                setMobileMenuOpen(false);
                            }}
                            sx={{
                                justifyContent: "space-between",
                                textTransform: "none",
                                px: 2,
                                mb: 2,
                            }}
                            endIcon={
                                <ArrowRight
                                    size={18}
                                    color={theme.palette.neutrals[70]}
                                    style={{ transform: "rotate(-45deg)" }}
                                />
                            }
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Workspace product</Typography>
                            </Box>
                        </Button>
                    )}
                    {!isSheets && (
                        <Button
                            color="secondary"
                            onClick={() => {
                                handleSheetsClick();
                                setMobileMenuOpen(false);
                            }}
                            sx={{
                                justifyContent: "space-between",
                                textTransform: "none",
                                px: 2,
                                mb: 2,
                            }}
                            endIcon={
                                <ArrowRight
                                    size={18}
                                    color={theme.palette.neutrals[70]}
                                    style={{ transform: "rotate(-45deg)" }}
                                />
                            }
                        >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Typography variant="body2">Sheets product</Typography>
                            </Box>
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = "https://calendly.com/noah-answergrid/30min";
                            setMobileMenuOpen(false);
                        }}
                        color="secondary"
                        sx={{
                            textTransform: "none",
                            // borderColor: "neutrals.30",
                            color: "white",
                            // "&:hover": { borderColor: "neutrals.30" },
                            mt: "auto",
                        }}
                    >
                        Book a demo
                    </Button>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

interface RouteButtonProps {
    text: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    endIcon?: React.ReactNode;
}

const RouteButton: React.FC<RouteButtonProps> = ({ text, onClick, endIcon }) => {
    return (
        <Button color="secondary" onClick={onClick} endIcon={endIcon} sx={{ textTransform: "none" }} disableRipple>
            {text}
        </Button>
    );
};
