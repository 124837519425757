import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useCurrentBreakpoint } from "../useCurrentBreakpoint";
import { TalkToUsButton } from "../talkToUsButton";
import ProductScreenshot from "../assets/images/product-screenshot.png";
import { MediaContainer } from "../MediaContainer";

const HEIGHT_OF_IMAGE_IN_ANSWERS_AT_SCALE_SECTION = 420;
const MOBILE_HEIGHT_OF_IMAGE_IN_ANSWERS_AT_SCALE_SECTION = 120;

export const AnswersAtScaleSection: React.FC = () => {
    const breakpoint = useCurrentBreakpoint();
    const isMobile = React.useMemo(() => {
        return breakpoint === "xxxs" || breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm";
    }, [breakpoint]);
    const height = React.useMemo(() => {
        if (isMobile) {
            return MOBILE_HEIGHT_OF_IMAGE_IN_ANSWERS_AT_SCALE_SECTION;
        }
        return HEIGHT_OF_IMAGE_IN_ANSWERS_AT_SCALE_SECTION;
    }, [isMobile]);
    return (
        <Box
            sx={theme => ({
                display: "flex",
                gap: 4,
                px: 6,
                bgcolor: "surface.25",
                border: 1,
                borderColor: "neutrals.30",
                minHeight: isMobile ? "450px" : "510px",
                height: isMobile ? "450px" : "510px",
                mx: 2,
                mt: 4,
                borderRadius: 2,
                overflow: "hidden",
                justifyContent: "space-between",
                [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                },
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignItems: "center",
                },
            })}
        >
            {/* Text section */}
            <Box
                sx={theme => ({
                    [theme.breakpoints.up("md")]: {
                        flex: 1,
                        minWidth: "400px",
                        maxWidth: "500px",
                        textAlign: "left",
                    },
                    [theme.breakpoints.down("sm")]: {
                        textAlign: "center",
                    },
                    mt: 8,
                })}
            >
                <Typography
                    variant={isMobile ? "h3" : "h2"}
                    sx={{
                        fontFamily: "'Garamond-Light-Cond'",
                        color: "secondary.main",
                        fontWeight: "500",
                        lineHeight: 1.2,
                        mb: 1,
                    }}
                    noWrap
                >
                    Start winning
                    <br />
                    with <i>AI today</i>
                </Typography>
                <TalkToUsButton />
            </Box>

            {/* Media section */}
            <Box
                sx={theme => ({
                    display: "flex",
                    alignItems: "flex-end",
                    [theme.breakpoints.down("sm")]: {
                        width: "100%",
                        justifyContent: "center",
                    },
                    [theme.breakpoints.up("md")]: {
                        mr: "5%",
                    },
                })}
            >
                <MediaContainer width={height * 1.7} height={height} startingBottom={isMobile ? 0 : 6}>
                    <img src={ProductScreenshot} alt="Product screenshot" style={{ width: "100%", height: "100%" }} />
                </MediaContainer>
            </Box>
        </Box>
    );
};
