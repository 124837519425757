import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as IntegrationDiagram } from "../assets/images/data-integration.svg";
import { VideoPlayer } from "../videoPlayer";

interface FeatureSection {
    title: string;
    description: string;
    media: React.ReactNode;
}

export const SECTIONS: FeatureSection[] = [
    {
        title: "Robust data integration",
        description:
            "Centralize your firm's knowledge with our extensive coverage of data source connectors for CRMs, ERPs, File stores, and more.",
        media: (
            <Box
                sx={theme => ({
                    width: "100%",
                    height: "100%",
                    bgcolor: "surface.50",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 2,
                    border: 1,
                    borderColor: "surface.100",
                    [theme.breakpoints.down("sm")]: {
                        height: "180px",
                        px: 2,
                    },
                    [theme.breakpoints.up("md")]: {
                        height: "350px",
                    },
                })}
            >
                <IntegrationDiagram />
            </Box>
        ),
    },
    {
        title: "Enterprise Search",
        description: "A platform to find answers, files, and internal experts powered by your internal knowledge.",
        media: <VideoPlayer src="https://AnswerGrid.b-cdn.net/Enterprise%20Search.m4v" />,
    },
    {
        title: "AI Research Agents",
        description:
            "Get deeper insights from your past projects, market research reports & expert call transcripts with clear citations.",
        media: <VideoPlayer src="https://AnswerGrid.b-cdn.net/AI%20Research%20Agents.m4v" />,
    },
    {
        title: "Business-critical use cases",
        description: "Build and deploy AI apps to automate and scale workflows to unlock real business outcomes.",
        media: <VideoPlayer src="https://AnswerGrid.b-cdn.net/Business-critical%20use%20cases.m4v" />,
    },
];
