import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Box2, Lock, SecurityUser, ShieldTick } from "iconsax-react";
import { styled } from "@mui/material/styles";
const StyledBox2 = styled(Box2)({
    "& path": {
        strokeWidth: 0.5,
    },
});

const StyledSecurityUser = styled(SecurityUser)({
    "& path": {
        strokeWidth: 0.5,
    },
});

const StyledLock = styled(Lock)({
    "& path:first-child": {
        strokeWidth: 0.5,
    },
    "& path:last-child": {
        strokeWidth: 1,
    },
});

const StyledShieldTick = styled(ShieldTick)({
    "& path": {
        strokeWidth: 0.5,
    },
});

export const SecuritySection: React.FC = () => {
    const theme = useTheme();
    return (
        <Box
            id="security-section"
            sx={{
                bgcolor: "surface.50",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 6,
                px: 2,
                pb: 2,
            }}
        >
            <Box sx={{ maxWidth: "600px", textAlign: "center", mb: { xxxs: 3, md: 5 } }}>
                <Typography
                    variant="caption"
                    sx={{
                        color: "secondary.main",
                        textTransform: "uppercase",
                        fontWeight: "medium",
                        mb: 2,
                        display: "block",
                    }}
                >
                    Enterprise-Grade
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: "'Garamond-Light-Cond'",
                        color: "secondary.main",
                        fontWeight: "500",
                        lineHeight: 1.2,
                    }}
                >
                    Security & Governance
                </Typography>
            </Box>

            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xxxs: "1fr",
                        sm: "1fr",
                        md: "repeat(4, 350px)",
                    },
                    gridTemplateRows: {
                        xxxs: "repeat(4, auto)",
                        sm: "repeat(4, auto)",
                        md: "240px",
                    },
                    gap: {
                        xxxs: 4,
                        md: 0,
                    },
                    width: "100%",
                    position: "relative",
                    justifyContent: "center",
                    borderTop: {
                        xxxs: "none",
                        md: `1px solid ${theme.palette.neutrals[30]}`,
                    },
                }}
            >
                {[
                    {
                        title: "We don't train on your data",
                        icon: <StyledBox2 variant="Linear" size={64} color={theme.palette.secondary.main} />,
                    },
                    {
                        title: "Role-based access controls",
                        icon: <StyledSecurityUser variant="Linear" size={64} color={theme.palette.secondary.main} />,
                    },
                    {
                        title: "Encryption in transit and at rest",
                        icon: <StyledLock variant="Linear" size={64} color={theme.palette.secondary.main} />,
                    },
                    {
                        title: "SOC II (Coming Soon)",
                        icon: <StyledShieldTick variant="Linear" size={64} color={theme.palette.secondary.main} />,
                    },
                ].map((item, index) => (
                    <Box
                        key={index}
                        sx={theme => ({
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                            borderColor: "neutrals.30",
                            borderRight: {
                                xxxs: "none",
                                md: index === 3 ? "none" : `1px solid ${theme.palette.neutrals[30]}`,
                            },
                        })}
                    >
                        <Box
                            sx={{
                                width: 64,
                                height: 64,
                                borderRadius: 2,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 3,
                            }}
                        >
                            {item.icon}
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                color: "neutrals.70",
                            }}
                        >
                            {item.title}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
