import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ReactGA from "react-ga4";
import { AppContentWithHeader } from "./AppContentWithHeader";
import { Pricing } from "./Pricing";
import { MediaContainer } from "./MediaContainer";
import { LanderMediaTopPart } from "./LanderMediaTopPart";
import { useVideoDimensions } from "./useVideoDimensions";

export const SheetsLander: React.FC = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/sheets",
        title: "Sheets Landing page",
    });

    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setContainerDimensions({
                    width: containerRef.current.clientWidth,
                    height: containerRef.current.clientHeight,
                });
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [videoWidth, videoHeight] = useVideoDimensions(containerDimensions);

    const video = (
        <MediaContainer width={videoWidth} height={videoHeight}>
            <iframe
                id="video-iframe"
                data-ph-capture-attribute-iframe="video-iframe"
                src="https://www.loom.com/embed/fe4e40fa000b4406910a9ce247079138?sid=9382c4c2-c6c5-4868-9aef-f9f9b636ef37?sid=b074217b-5d3f-4103-b2c7-7297b6ff18b5?hide_share=true&hideEmbedTopBar=true&hide_title=true&hide_owner=true"
                frameBorder="0"
                allowFullScreen
                style={{ width: "100%", height: "100%" }}
            />
        </MediaContainer>
    );

    return (
        <AppContentWithHeader>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center" }}>
                <LanderMediaTopPart ref={containerRef} topPart={<TopMainContent />} media={video} />
                <Pricing />
            </Box>
        </AppContentWithHeader>
    );
};

const TopMainContent: React.FC = () => {
    return (
        <Box
            sx={theme => ({
                mx: 1,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                // rowGap: breakpoint === "xxxs" ? 0 : 3,
                overflowY: "hidden",
                overflowX: "hidden",
                [theme.breakpoints.only("xxxs")]: {
                    rowGap: 0,
                },
                [theme.breakpoints.only("xxs")]: {
                    rowGap: 2,
                },
                [theme.breakpoints.up("xs")]: {
                    rowGap: 4,
                },
            })}
        >
            <Box
                sx={theme => ({
                    display: "flex",
                    flexDirection: "column",
                    px: 6,
                    [theme.breakpoints.only("xxxs")]: {
                        rowGap: 3,
                    },
                    [theme.breakpoints.only("xxs")]: {
                        rowGap: 10,
                    },
                    [theme.breakpoints.only("xs")]: {
                        rowGap: 4,
                    },
                    [theme.breakpoints.only("sm")]: {
                        rowGap: 4,
                    },
                    // rest
                    [theme.breakpoints.up("md")]: {
                        rowGap: 4,
                    },
                })}
            >
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontFamily: "'Garamond-Light-Cond'",
                            color: "secondary.main",
                            fontWeight: "500",
                            lineHeight: 1.0,
                        }}
                    >
                        Answers
                        <wbr />
                        &nbsp;at&nbsp;<i>scale</i>
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: "secondary.main",
                            maxWidth: "600px",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                        }}
                    >
                        AnswerGrid Sheets is a tool for accelerating commercial web research. Discover competitors,
                        generate leads, research market players and more.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignSelf: "center", alignItems: "center", gap: 2 }}>
                    <Button
                        id="try-it-button"
                        data-ph-capture-attribute-button="try-it-button"
                        variant="contained"
                        color="secondary"
                        href="https://app.answergrid.ai/try-it"
                        target="_blank"
                        size="large"
                        sx={{
                            color: "white",
                            borderRadius: 50,
                            textTransform: "unset",
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
                            Try it out
                        </Typography>
                    </Button>
                    <Button
                        id="book-demo-button"
                        data-ph-capture-attribute-button="book-demo-button"
                        variant="outlined"
                        size="large"
                        href="https://calendly.com/noah-answergrid/15-min-meeting"
                        target="_blank"
                        sx={{
                            color: "secondary.main",
                            borderColor: "divider",
                            textTransform: "unset",
                            borderRadius: 8,
                            fontWeight: "medium",
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: "medium" }} noWrap>
                            Book a demo
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
