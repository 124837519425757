import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { SheetsLander } from "./SheetsLander";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { Company } from "./Company";
import { KnowledgeLander } from "./KnowledgeLander";

ReactGA.initialize("G-KCQLPJBT0P");

const posthogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Routes>
                        <Route path="/" element={<KnowledgeLander />} />
                        <Route path="/sheets" element={<SheetsLander />} />
                        <Route path="/company" element={<Company />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </PostHogProvider>
    </React.StrictMode>,
);

if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
    // Check if web vitals have already been reported this session
    if (!sessionStorage.getItem("webVitalsReported")) {
        reportWebVitals(metric => {
            posthog.capture("web_vitals", {
                name: metric.name,
                value: metric.value,
                id: metric.id,
                delta: metric.delta,
                entries: metric.entries.map(entry => JSON.stringify(entry)),
            });
        });
        // Set flag in sessionStorage to indicate web vitals have been reported
        sessionStorage.setItem("webVitalsReported", "true");
    }
}
