import * as React from "react";

interface VideoPlayerProps {
    src: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ src }) => {
    return (
        <video
            autoPlay
            loop
            muted
            playsInline
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <source src={src} type="video/mp4" />
            <source src={src} type="video/m4v" />
            Your browser does not support the video tag.
        </video>
    );
};
