import { useTheme, useMediaQuery } from "@mui/material";
// Helper function to determine current breakpoint
export const useCurrentBreakpoint = () => {
    const theme = useTheme();
    const isXxxs = useMediaQuery(theme.breakpoints.only("xxxs"));
    const isXxs = useMediaQuery(theme.breakpoints.only("xxs"));
    const isXs = useMediaQuery(theme.breakpoints.only("xs"));
    const isSm = useMediaQuery(theme.breakpoints.only("sm"));
    const isMd = useMediaQuery(theme.breakpoints.only("md"));
    const isLg = useMediaQuery(theme.breakpoints.only("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    if (isXl) return "xl";
    if (isLg) return "lg";
    if (isMd) return "md";
    if (isSm) return "sm";
    if (isXs) return "xs";
    if (isXxs) return "xxs";
    if (isXxxs) return "xxxs";
    return "xs";
};

export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpoint();
    return breakpoint === "xxxs" || breakpoint === "xxs" || breakpoint === "xs" || breakpoint === "sm";
};
